.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1c2833;  
  padding: 2rem;
  box-sizing: border-box;
}

.home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; 
}

.home-left {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
}

.octogon-sphere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.home-left-content {
  position: relative;
  z-index: 2; 
  text-align: center;
  width: 100%;
  height: 40vh;
}

.home-left-title {
  margin-bottom: 2rem;
  width: 100%;
}
.home-title {
  font-size: 5vw;
  margin: 0;
  color: orange;  
  font-family: "Lilita One", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
margin-bottom: 2rem;
}

.home-subtext {
  font-size: 3vw; 
  color: #666;
  border-right: 2px solid;
  white-space: nowrap;
  overflow: hidden;

}

.home-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.home-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  position: relative;
  overflow: hidden;
}

.home-left-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;
  flex-wrap: wrap;
  width: 100%;
}

.home-btn {
  background-color: #072679;
  color: white;
  border: none;
  padding: 1rem 2rem; 
  border-radius: 2rem; 
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.home-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}

.home-btn:hover::before {
  transform: scaleX(1);
}

.home-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.home-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2rem;
  justify-content: center;
}



.home-item:hover {
  color: #0056b3;
  transform: scale(1.2);
}

.profile-text {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #CFD8DC; 
  font-size: 1.3rem; 
  line-height: 1.8; 
  text-align: justify; 
  margin: 0 auto;
  padding: 1rem; 
  animation: textFadeIn 1.5s ease-in-out;
}
.language {
  width: 100%;
  color: #E8EAF6;
}

.language-title {
  font-size: 3rem;
  color: orange; 
  font-family: "Lilita One", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.language-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 6.5rem;
  background-color: #1c2833; 
}

.language-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  gap: 1rem;
  margin-bottom: 5rem;
}

.language-line {
  display: flex;
  padding: 0 2rem;
  gap: 1rem;
  width: 90%; 
  margin: 0.5rem 0;
}

.language-container-frontend,
.language-container-backend,
.language-container-databases,
.language-container-orders {
  width: 43vw;
  height: 39.5vh;
  padding: 2rem 2.5rem;
  border: 1px solid #2E4053;
  border-radius: 1rem;
  background-color: #2C3E50;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.language-subtitle {
  position: sticky;
  top: 0;
  background-color: #2C3E50;
  z-index: 1;
  padding: 0.5rem 0;
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  color: #E8EAF6;
  margin-bottom: 1rem;
}

.scrollable-content {
  overflow-y: auto; 
  max-height: calc(45.5vh - 1rem);
  padding-right: 1rem;
  width: 100%;
  scrollbar-width: thin; 
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(44, 62, 80, 0.8); 
}

.scrollable-content::-webkit-scrollbar {
  width: 8px; 
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(44, 62, 80, 0.8); 
    border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); 
  border-radius: 10px;
  border: 2px solid rgba(44, 62, 80, 0.8); 
}

.language-container-frontend:hover,
.language-container-backend:hover,
.language-container-databases:hover,
.language-container-orders:hover {
  box-shadow: 0 0 15px 5px rgba(52, 152, 219, 0.8),
              0 0 30px 10px rgba(52, 152, 219, 0.5); 
  border-color: #3498DB; 
}

.language-item {
  width: 100%;
}

.language-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.language-icon-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.language-icon-name label {
  font-size: 1em; 
  color: #E0E0E0; 
}

.progress-label {
  font-size: 0.9em;
  color: #E0E0E0; 
}

.custom-linear-progress {
  width: 100% ; 
  margin-bottom: 0.9rem !important;
}

.language-item .MuiLinearProgress-root {
  width: 100%;
  background-color: #546E7A; 
}

.language-item .MuiLinearProgress-bar {
  background-color: #FF9800; 
}

.formation {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #1c2833;
  color: #f5f5f5;
  padding: 2rem 5vw; 
}

.formation-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: orange;
  font-family: "Lilita One", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.formation-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.ui.ribbon.label {
  color: #ffffff;
  font-weight: bold;
  background-color: #3498db;
  margin-bottom: 2%;
  font-size: 1.2rem;
}

.formation-year-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
}

.ribbon-left .formation-year {
  background-color: #0423d4;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ribbon-right .formation-year {
  background-color: orange;
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ui.card > .content {
  position: relative;
  padding-top: 3rem;
  font-size: 1rem;
  background-color: #2a2d34;
  color: #e0e0e0;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 12px;
  transition: all 0.3s ease-in-out;
}

.ui.card:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.formation-header {
  color: #f5f5f5;
  font-family: "Protest Riot", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 1rem;
}

.formation-description {
  padding: 1rem;
  font-size: 16px;
}

.ui.fluid.card {
  width: 45vw; 
  max-width: 600px; 
  min-width: 280px; 
  margin: 0rem auto; 
}

@media (max-width: 1200px) {
  .formation-title {
    font-size: 2.5rem;
  }
  .formation-header {
    font-size: 1.5rem;
  }
  .ui.card > .content {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .formation-title {
    font-size: 2.2rem;
  }
  .formation-header {
    font-size: 1.4rem;
  }
  .ui.card > .content {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .formation-title {
    font-size: 2rem;
  }
  .formation-header {
    font-size: 1.2rem;
  }
  .ui.card > .content {
    font-size: 0.8rem;
  }
  .ui.fluid.card {
    width: 80vw;
  }
}

@media (max-width: 576px) {
  .formation-title {
    font-size: 1.8rem;
  }
  .formation-header {
    font-size: 1rem;
  }
  .ui.card > .content {
    font-size: 0.75rem;
  }
  .ui.fluid.card {
    width: 90vw;
  }
}

.canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  canvas {
    width: 100% ;
    height: 100%;
    display: block;
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 10;
  }
  
 
 
  
.profile {
  background-color: #1E2A38; 
  min-height: 100vh;
  width: 100%;
  color: #E8EAF6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.profile-container {
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin-top: 5rem;
}

.profile-title {
  font-size: 3rem;
  text-align: center;
  color: orange;
  font-family: "Lilita One", sans-serif;
  font-weight: bold;
  animation: titleFadeIn 1s ease-in-out;
}

@keyframes titleFadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.profile-text {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #CFD8DC; 
  font-size: 1.5rem;
  line-height: 1.8;
  text-align: justify; 
  animation: textFadeIn 1.5s ease-in-out;
  max-width: 800px; 
}

@keyframes textFadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  flex-direction: column;
  gap: 1rem; 
}

.profile-header-left {
  flex: 1;
  background-color: transparent;
  padding: 1rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #E8EAF6; 
}

